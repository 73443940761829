<template>
	<div class="dashboard pl pl100 pr100">
		<el-card>
			<div class="flex_r f_jc_b account p20">
				<div class="flex_r f_ai_c pl10">
					<div class="posr account_img">
						<img class="brs_4" height="88" width="88" :src="userInfo.avatar" alt="">
						<span class="posa fs10 tc cr_fff">认证</span>
					</div>
					<div class="flex_c f_jc_c pl15">
						<span class="pb10 fs18">{{ userInfo.username }}</span>
						<span>ID：{{ userInfo.id }}</span>
					</div>
				</div>
				<ul class="flex_r pr20">
					<li class="flex_c f_jc_c f_ai_c">
						<span class="pb10">待审核</span>
						<router-link tag="a" target="" :to="{path:'/goodsCheck',query: {activeName:'wait'}}">{{sh.dsh}}</router-link>
					</li>
					<li class="flex_c f_jc_c f_ai_c">
						<span class="pb10">待寄件</span>
						<router-link tag="a" target="" :to="{path:'/specimen',query: {}}">{{sh.djj}}</router-link>
					</li>
					<li class="flex_c f_jc_c f_ai_c">
						<span class="pb10">待排期</span>
						<router-link tag="a" target="" :to="{path:'/goodsCheck',query: {activeName:'dpq'}}">{{sh.dpq}}</router-link>
					</li>
					<li class="flex_c f_jc_c f_ai_c">
						<span class="pb10">待直播</span>
						<router-link tag="a" target="" :to="{path:'/liveCount'}">{{sh.dzb}}</router-link>
					</li>
					<li class="flex_c f_jc_c f_ai_c">
						<span class="pb10">待完成</span>
						<router-link tag="a" target="" :to="{path:'/goodsCheck',query: {activeName:'dwc'}}">{{sh.dwc}}</router-link>
					</li>
<!--					<li class="flex_c f_jc_c f_ai_c">-->
<!--						<span class="pb10">活动商品审核</span>-->
<!--						<span class="pt10 fs16">{{sh.hsh}}</span>-->
<!--					</li>-->
				</ul>
			</div>
		</el-card>
		<e-card title="报名进度">
			<div slot="title">
				<el-button @click="dayValueClick(7)" :type="dayValue === 7 ? 'primary' : ''">近7天</el-button>
				<el-button @click="dayValueClick(15)" :type="dayValue === 15 ? 'primary' : ''">近15天</el-button>
				<el-button @click="dayValueClick(30)" :type="dayValue === 30 ? 'primary' : ''">近30天</el-button>
				<el-button @click="dayValueClick(60)" :type="dayValue === 60 ? 'primary' : ''">近60天</el-button>
				<el-date-picker
					class="ml10 mr10"
					@change="dayChange"
					v-model="baotime"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<el-button type="primary"><i class="el-icon-upload2"></i>导出数据</el-button>
			</div>
			<div class="echart">
				<e-charts id="idBar" ref="echarts" :options="optionBar" class="h100" />
				<!-- <Empty /> -->
			</div>
		</e-card>
		<el-row :gutter="20">
			<el-col :span="16">
				<e-card title="场次" operation="查看更多 >" pages="/liveCount">
					<div slot="title">
						<el-date-picker
						style="width:180px"
						v-model="zbtimes"
						type="date"
						@change="zbSelect"
						placeholder="选择日期">
						</el-date-picker>
					</div>
					<div class="item" v-if="zbLists.length>0">
						<div style="line-height: 25px;cursor: pointer;" class="flex_r f_jc_a pb4" v-for="zb in zbLists" :key="zb" @click="toZb(zb.id)">
							<span>{{zb.zb_name}}</span>
							<span style="color: #999;">{{zb.zbtime}}</span>
							<span>{{zb.username}}</span>
						</div>
						<!-- <Empty /> -->
					</div>
					<div class="item" v-else>
						<Empty />
					</div>
				</e-card>
			</el-col>
			<el-col :span="8">
				<e-card title="" operation="查看更多 >">
					<div slot="title">
						<span class="pr20 cur_p">公告</span>
						<span class="cur_p">通知</span>
					</div>
					<div class="item">
						<Empty />
					</div>
				</e-card>
			</el-col>
			<el-col :span="8">
				<e-card title="审核">
					<div slot="title">
					</div>
					<div class="item">
						<e-charts id="idLineSh" ref="shecharts" :options="shOption" class="h100" />
						<!-- <Empty /> -->
					</div>
				</e-card>
			</el-col>
			<el-col :span="8">
				<e-card title="入库">
					<div slot="title">
					</div>
					<div class="item">
						<e-charts id="idLineRk" ref="rkecharts" :options="rkOption" class="h100" />
					</div>
				</e-card>
			</el-col>
			<el-col :span="8">
				<e-card title="排期">
					<div slot="title">
					</div>
					<div class="item">
						<e-charts id="idLinePq" ref="pqecharts" :options="pqOption" class="h100" />
					</div>
				</e-card>
			</el-col>
		</el-row>
		<e-card title="全网热卖商品" >
			<div slot="title">
				<el-button type="primary">昨天</el-button>
<!--				<el-button>近3天</el-button>-->
<!--				<el-button>近7天</el-button>-->
<!--				<el-button>近30天</el-button>-->
			</div>
			<div class="flex_r flex_wrap">
				<div class="tc mt10 mb10" style="width: 20%;cursor: pointer" v-for="goods in goodsRank" :key="goods" @click="$router.push({path:'/commodityDetails',query: {id:goods.id}})">
					<img class="mb2" :src="goods.pict_url" height="60" width="60" alt="" srcset="">
					<div style="width: 130px" class="ellipsis_1 mt8 mb8 mauto">{{goods.title}} </div>
					<div class="pd10 flex_r f_ai_c f_jc_a b_num">
						<div class="flex_c f_ai_c f_jc_a">
							<span class="cr_999 fs12">平台</span>
							<img v-if="goods.web == 0" class="brs50 pt4" src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/tb.png?v=1622643440208" height="16" width="16" alt="" srcset="">
							<img v-if="goods.web == 1" class="brs50 pt4" src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/tm.png?v=1622643440208" height="16" width="16" alt="" srcset="">
						</div>
						<div class="flex_c f_ai_c f_jc_a">
							<span class="cr_999 fs12">销量</span>
							<span class="pt4">{{goods.volume}}</span>
						</div>
					</div>
				</div>
			</div>
		</e-card>
		<e-card title="收藏主播">
			<div slot="title">
				<el-button type="primary">+添加主播</el-button>
			</div>
			<div class="item">
				<Empty />
			</div>
		</e-card>
		<e-card title="操作记录">
			<div slot="title">
				<el-button @click="logDayValueClick(7)" :type="logDayValue === 7 ? 'primary' : ''">近7天</el-button>
				<el-button @click="logDayValueClick(15)" :type="logDayValue === 15 ? 'primary' : ''">近15天</el-button>
				<el-button @click="logDayValueClick(30)" :type="logDayValue === 30 ? 'primary' : ''">近30天</el-button>
				<el-button @click="logDayValueClick(60)" :type="logDayValue === 60 ? 'primary' : ''">近60天</el-button>
				<el-date-picker
					class="ml10 mr10"
					v-model="logbaotime"
					type="daterange"
					@change="logDayChange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<el-button type="primary"><i class="el-icon-upload2"></i>导出数据</el-button>
			</div>
			<div class="echart">
				<e-table ref="tables" :tableCols="recordTableConf" :dataOrigin="dataOrigin" :showPagination="false" />
				<!-- <Empty /> -->
			</div>
		</e-card>
		<e-card title="经营概况">
			<div slot="title">
				<el-button @click="baoValueClick(7)" :type="anchorGoodsDayValue === 7 ? 'primary' : ''">近7天</el-button>
				<el-button @click="baoValueClick(15)" :type="anchorGoodsDayValue === 15 ? 'primary' : ''">近15天</el-button>
				<el-button @click="baoValueClick(30)" :type="anchorGoodsDayValue === 30 ? 'primary' : ''">近30天</el-button>
				<el-button @click="baoValueClick(60)" :type="anchorGoodsDayValue === 60 ? 'primary' : ''">近60天</el-button>
			</div>
			<div class="echart">
				<el-row :gutter="20">
					<el-col :span="12">
						<div>
							<div class="flex_r f_ai_c f_jc_c cr_999"><span class="fs22 fw_b pr6 cr_007">·</span>报名商品数</div>
<!--							<div class="flex_r f_jc_c f_ai_c">-->
<!--								<span class="fs16 fw_b pr20">0</span>-->
<!--								<ul class="pl20 fs12">-->
<!--									<li class="pt10 pb10">-->
<!--										<span class="cr_999">日环比</span>-->
<!--										<i class="el-icon-caret-bottom pl4 pr4 cr_18a"></i>-->
<!--										<span class="cr_18a">-100%</span>-->
<!--									</li>-->
<!--									<li class="pt10 pb10">-->
<!--										<span class="cr_999">周环比</span>-->
<!--										<i class="el-icon-caret-top pl4 pr4 cr_ff0"></i>-->
<!--										<span class="cr_ff0">-100%</span>-->
<!--									</li>-->
<!--								</ul>-->
<!--							</div>-->
						</div>
						<div class="line">
							<e-charts id="idLine" ref="baoecharts" :options="baoOption" class="h100" />
						</div>
					</el-col>
					<el-col :span="12">
						<div>
							<div class="flex_r f_ai_c f_jc_c cr_999"><span class="fs22 fw_b pr6 cr_007">·</span>直播场次</div>
<!--							<div class="flex_r f_jc_c f_ai_c">-->
<!--								<span class="fs16 fw_b pr20">0</span>-->
<!--								<ul class="pl20 fs12">-->
<!--									<li class="pt10 pb10">-->
<!--										<span class="cr_999">日环比</span>-->
<!--										<i class="el-icon-caret-bottom pl4 pr4 cr_18a"></i>-->
<!--										<span class="cr_18a">-100%</span>-->
<!--									</li>-->
<!--									<li class="pt10 pb10">-->
<!--										<span class="cr_999">周环比</span>-->
<!--										<i class="el-icon-caret-top pl4 pr4 cr_ff0"></i>-->
<!--										<span class="cr_ff0">-100%</span>-->
<!--									</li>-->
<!--								</ul>-->
<!--							</div>-->
						</div>
						<div class="line">
							<e-charts id="idLine2" ref="zbecharts" :options="zbOption" class="h100" />
						</div>
					</el-col>
					<el-col :span="8"></el-col>
				</el-row>
				<!-- <Empty /> -->
			</div>
		</e-card>
		<e-card title="报销商品分析">
			<div slot="title">
				<el-button @click="lmValueClick(7)" :type="lmDayValue === 7 ? 'primary' : ''">近7天</el-button>
				<el-button @click="lmValueClick(15)" :type="lmDayValue === 15 ? 'primary' : ''">近15天</el-button>
				<el-button @click="lmValueClick(30)" :type="lmDayValue === 30 ? 'primary' : ''">近30天</el-button>
				<el-button @click="lmValueClick(60)" :type="lmDayValue === 60 ? 'primary' : ''">近60天</el-button>
			</div>
			<div class="echart">
				<el-row :gutter="20">
					<el-col :span="12">
						<div class="fs12 flex_r f_ai_c"><span class="fs22 fw_b pr6 cr_007">·</span>类目报名占比</div>
						<div class="item">
							<e-charts id="idPie2" ref="lmecharts" :options="lmoptionPie" class="h100" />
							<!-- <Empty /> -->
						</div>
					</el-col>
					<el-col :span="12">
						<div class="fs12 flex_r f_ai_c"><span class="fs22 fw_b pr6 cr_007">·</span>类目报名数量趋势</div>
						<div class="line">
							<e-charts id="idLine3" ref="lmlecharts" :options="lmoptionLine" class="h100" />
							<!-- <Empty /> -->
						</div>
					</el-col>
				</el-row>
			</div>
		</e-card>
	</div>
</template>

<script>
import {optionBar, optionPie, recordTableConf, optionLine} from '@data/dashboard'
export default {
	name: 'Dashboard',
	data() {
		return {
			optionBar,
			optionPie,
			recordTableConf,
			optionLine,
			dataOrigin: {
				data: []
			},
			dayValue: 7,
			logDayValue: 7,
			userInfo: {
				avatar: "",
				username: "",
				mobile: ""
			},
			sh: {
				dsh: 0,
				djj: 0,
				dpq: 0,
				dzb: 0,
				dwc: 0,
				hsh: 0
			},
			zbLists: [],
			zbtimes: '',
			goodsRank: [],
			baotime: '',
			logbaotime: '',
			shOption: [],
			rkOption: [],
			pqOption: [],
			anchordayValue: 7,
			lmoptionPie: [],
			lmoptionLine: [],
			anchorGoodsDayValue: 7,
			anchorGoodsBaotime: '',
			zbOption: [],
			baoOption: [],
			lmDayValue: 7
		}
	},
	methods: {
		toZb(id){
			this.$router.push({path:'/liveDetail',query:{id:id}});
		},
		zbSelect(){
			this.GetZbLists();
		},
		dayChange(){
			this.dayValue = ''
			this.EntrGoodsData()
		},
		logDayChange(){
			this.$refs.tables.loading = true
			this.logDayValue = ''
			this.GetConsoleLogLists()
		},
		dayValueClick(value){
			this.dayValue = value
			this.baotime = ''
			this.EntrGoodsData()
		},
		logDayValueClick(value){
			this.$refs.tables.loading = true
			this.logDayValue = value
			this.logbaotime = ''
			this.GetConsoleLogLists()
		},
		baoValueClick(value){
			this.anchorGoodsDayValue = value
			this.AnchorGoodsData()
		},
		lmValueClick(value){
			this.lmDayValue = value
			this.AnchorCateEntrGoodsData()
		},
		GetAuUserInfo(){
			let that = this
			this.get('?server=live.anchor.info.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.userInfo.avatar = response.data.avatar
				that.userInfo.username = response.data.username
				that.userInfo.id = response.data.id
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		EntrGoodsData(){
			let that = this

			this.get('?server=live.entr.goods.date.count.get',{type:this.dayValue, baotime:this.baotime}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				let columLabel = response.data.columnLabel;
				that.optionBar = {
					legend: {
						data:columLabel
					},
					color: ['cyan'],
					tooltip: {},
					xAxis : [
						{
							type : 'category',
							data : response.data.name,
							axisTick: {
								alignWithLabel: true
							}
						}
					],
					yAxis: {},
					grid: {
						left: '3%',
						right: '3%',
						bottom: '3%',
						containLabel: true
					},
					// Declare several bar series, each will be mapped
					// to a column of dataset.source by default.
					series: response.data.series
				};
				// console.log(that.optionBar)
				that.$refs.echarts.initEcharts();
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetZbLists(){
			let that = this
			this.zbLists = []
			// console.log(that.dataOrigin)
			this.get('?server=live.zb.time.lists.get',{time:this.zbtimes}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data.total)

				that.zbLists = response.data.data
				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetGoodsRankLists(){
			let that = this
			this.zbLists = []
			// console.log(that.dataOrigin)
			this.get('?server=Live.Hot.Goods.Ranking.Get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data.total)

				that.goodsRank = response.data
				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetConsoleLogLists(){
			let that = this
			this.dataOrigin.data = []
			// console.log(that.dataOrigin)
			this.get('?server=Live.console.date.count.Get',{type:this.logDayValue, baotime:this.logbaotime}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data.total)

				that.dataOrigin.data.push(response.data)
				that.$refs.tables.loading = false
				that.$refs.tables.initData();

				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		AnchorEntrGoodsData(){
			let that = this

			this.get('?server=live.anchor.entr.goods.group.cate.date.count.get',{type:this.anchordayValue}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				that.shOption = {
					grid: {
						left: '3%',
						right: '3%',
						bottom: '3%',
						top: '10%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: response.data.name
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: response.data.series[0].data,
						type: 'line',
						smooth: true
					}]
				};
				that.rkOption = {
					grid: {
						left: '3%',
						right: '3%',
						bottom: '3%',
						top: '10%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: response.data.name
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: response.data.series[1].data,
						type: 'line',
						smooth: true
					}]
				};
				that.pqOption = {
					grid: {
						left: '3%',
						right: '3%',
						bottom: '3%',
						top: '10%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: response.data.name
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: response.data.series[2].data,
						type: 'line',
						smooth: true
					}]
				};
				// console.log(that.optionBar)
				that.$refs.shecharts.initEcharts();
				that.$refs.rkecharts.initEcharts();
				that.$refs.pqecharts.initEcharts();
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		AnchorGoodsData(){
			let that = this

			this.get('?server=live.anchor.entr.goods.date.count.get',{type:this.anchorGoodsDayValue, baotime:this.anchorGoodsBaotime}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				that.baoOption = {
					grid: {
						left: '3%',
						right: '3%',
						bottom: '3%',
						top: '10%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: response.data.name
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: response.data.series[0].data,
						type: 'line',
						smooth: true
					}]
				};
				that.zbOption = {
					grid: {
						left: '3%',
						right: '3%',
						bottom: '3%',
						top: '10%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: response.data.name
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: response.data.series[1].data,
						type: 'line',
						smooth: true
					}]
				};
				// console.log(that.optionBar)
				that.$refs.baoecharts.initEcharts();
				that.$refs.zbecharts.initEcharts();
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		AnchorCateEntrGoodsData(){
			let that = this

			this.get('?server=live.anchor.entr.goods.group.cate.count.get',{type:this.lmDayValue}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response.data.count)
				// that.lmoptionPie = {
				// 	tooltip: {
				// 		trigger: 'item'
				// 	},
				// 	legend: {
				// 		top: '5%',
				// 		left: 'center'
				// 	},
				// 	series: [
				// 		{
				// 			name: '销售额',
				// 			type: 'pie',
				// 			radius: ['40%', '70%'],
				// 			avoidLabelOverlap: false,
				// 			itemStyle: {
				// 				borderRadius: 10,
				// 				borderColor: '#fff',
				// 				borderWidth: 2
				// 			},
				// 			label: {
				// 				show: false,
				// 				position: 'center'
				// 			},
				// 			emphasis: {
				// 				label: {
				// 					show: true,
				// 					fontSize: '40',
				// 					fontWeight: 'bold'
				// 				}
				// 			},
				// 			labelLine: {
				// 				show: false
				// 			},
				// 			data: [
				// 				{value:335, name:'直接访问'},
				// 				{value:310, name:'邮件营销'},
				// 				{value:274, name:'联盟广告'},
				// 				{value:235, name:'视频广告'},
				// 			]
				// 		}
				// 	]
				// };
				that.lmoptionPie = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						top: '5%',
						left: 'center'
					},
					series: [{
						data: response.data.count,
						type: 'pie',
						smooth: true
					}]
				};
				that.lmoptionLine = {
					grid: {
						left: '3%',
						right: '3%',
						bottom: '3%',
						top: '10%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: response.data.name
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						data: response.data.series[1].data,
						type: 'line',
						smooth: true
					}]
				};
				// console.log(that.optionBar)
				that.$refs.lmecharts.initEcharts();
				that.$refs.lmlecharts.initEcharts();
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		let that = this
		this.GetAuUserInfo()
		this.EntrGoodsData()
		this.GetZbLists()
		this.GetGoodsRankLists()
		this.AnchorEntrGoodsData()
		this.AnchorCateEntrGoodsData()
		this.AnchorGoodsData()
		this.$nextTick(() => {
			this.$refs.tables.loading = true
		});
		this.GetConsoleLogLists()
		this.get('?server=live.user.data.get',{}).
		then(function (response) {
			//这里只会在接口是成功状态返回
			//联网成功的回调
			console.log(response)
			that.sh.dsh = response.data.n_sh_count
			that.sh.dzb = response.data.zb_count
			that.sh.djj = response.data.djj_count
			that.sh.dqp = response.data.dpq_count
			that.sh.dwc = response.data.dwc_count
		}).catch(function (error) {
			//这里只会在接口是失败状态返回，不需要去处理错误提示
			console.log(error);
		});
	}
}
</script>

<style lang="scss" scoped>
.account {
	height: 88px;
	.account_img {
		span {
			height: 20px;
			left: 0;
			right: 0;
			bottom: 1px;
			line-height: 20px;
			background-color: rgba($color: #000000, $alpha: .1);
		}
	}
	li {
		height: 88px;
		padding-left: 50px;
	}
}
.echart {
	height: 200px;
}
.item {
	height: 200px;
}
.b_num {
	margin: 0 auto;
	width: 140px;
    height: 48px;
    background: #f9f9f9;
    border-radius: 24px;
    color: #999;
    font-size: 12px;
}
.time_row {
	line-height: 34px;
	border-bottom: 1px solid #eee;
	&.head {
		background-color: rgb(246, 249, 253);
		line-height: 30px;
	}
	span {
		width: 33.33%;
		text-align: center;
	}
}
.line {
	height: 150px;
}
</style>

<style lang="scss">
.el-card.is-always-shadow {
	box-shadow: 0 0px 5px 0 rgb(0 0 0 / 6%);
}
.el-card__body {
	padding: 10px;
}
.h100 {
	height: 100%;
}
</style>